import Dialog from '@bit/grupo_avenida.components.dialog';
import Typography from '@bit/grupo_avenida.components.typography';
import {Trans} from '@lingui/react';
import Cookies from 'js-cookie';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

const CopaNPSAlert = () => {
  const [dialog, setDialog] = useState(false);
  const history = useHistory();
  const cookieName = 'copaNPSDialogShown';

  const handleCloseDialog = () => {
    setDialog(false);
    Cookies.set(cookieName, 'true', {expires: 0.25});
  };

  const handleOpenCopaNPS = () => {
    history.push('/campanha/copa-nps');
    handleCloseDialog();
  };

  useEffect(() => {
    const cookieExists = Cookies.get(cookieName);
    if (!cookieExists) {
      setDialog(true);
    }
  }, []);

  return (
    <Dialog
      open={dialog}
      onClose={handleCloseDialog}
      primaryButtonAction={handleOpenCopaNPS}
      secondaryButtonAction={handleCloseDialog}
      title={
        <Typography size={18} weight="medium" lineHeight={22}>
          Se é gol é EVA!!! Ela pede nós marcamos!
        </Typography>
      }
      primaryButtonText={<Trans id="common.action.view" />}
      secondaryButtonText={<Trans id="common.action.close" />}
    >
      <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={`${process.env.PUBLIC_URL}/imgs/copaNPS/copa-nps3.jpg`} alt="Copa NPS" />
    </Dialog>
  );
};

export default CopaNPSAlert;
