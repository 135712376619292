import React from 'react';

import CopaNPSAlert from '../campaign/CopaNPS/CopaNPSAlert';
import ReleaseNotesAlert from '../releaseNotes/releaseNotesAlert/ReleaseNotesAlert';
import Toast from '../toast/toast';
import NotificationUserActions from './notificationUserActions';

export const BaseComponents = () => {
  return (
    <React.Fragment>
      <NotificationUserActions />
      <CopaNPSAlert />
      <ReleaseNotesAlert />
      <Toast />
    </React.Fragment>
  );
};

export default BaseComponents;
